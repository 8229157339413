import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const Contact = () => {

    return (

        <>
            <section className="bg-theme-accent1 my-16 font-sans-serif">
                <div className="grid text-theme-primary-text max-w-screen-xl grid-cols-1 gap-8 px-8 pb-16 md:py-16 mx-auto rounded-lg md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 bg-coolGray-100 text-coolGray-800">
                    <div className="flex flex-col justify-between">
                        <div className="flex justify-around m-auto">
                            <StaticImage src="../images/think-positive-paper-back-surprised-woman-holding-a-book.jpg" alt="Think Positive, Be Happy, Start Living" placeholder="blurred" layout="constrained" />
                        </div>
                    </div>
                    <div className="self-end row-start-1 md:col-start-2">
                        <div className="space-y-2 pt-16 md:pt-0">
                            <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-tight">Get In Touch!</h2>
                            <div className="text-coolGray-600 text-base sm:text-lg md:text-xl">We'd love to hear from you.</div>
                        </div>
                        <form className="space-y-6 ng-untouched ng-pristine ng-valid"
                            name="Contact"
                            method="post"
                            data-netlify="true">
                            <input type="hidden" name="form-name" value="Contact" />
                            <input type="hidden" name="send_to" id="send_to" value="contact@ajsatori.com" />
                            <input type="hidden" name="website_org" id="website_org" value="AJ Satori" />
                            <div>
                                <label htmlFor="name" className="text-base sm:text-lg md:text-xl">Full name
                                    <input id="name" name="name" required type="text" placeholder="" className="w-full p-3 rounded border-theme-accent3 border-2 text-theme-primary-text" />
                                </label>
                            </div>
                            <div>
                                <label htmlFor="email" className="text-base sm:text-lg md:text-xl">Email
                                    <input id="email" name="email" required type="email" className="w-full p-3 rounded border-theme-accent3 border-2 text-theme-primary-text" />
                                </label>
                            </div>
                            <div>
                                <label htmlFor="message" className="text-base sm:text-lg md:text-xl">Message
                                    <textarea id="message" name="message" required rows="3" className="w-full p-3 rounded border-theme-accent3 border-2 text-theme-primary-text"></textarea>
                                </label>
                            </div>
                            <button type="submit" className="w-full p-3 text-base sm:text-lg md:text-xl font-bold tracking-wide uppercase rounded bg-theme-accent2 border-2 border-theme-primary-text text-theme-primary-text">Send Message</button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact