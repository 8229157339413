import React from "react";

import logoAvAmazon from '../images/logoAvAmazon.svg'
import logoAvAmazonKindle from '../images/logoAvAmazonKindle.svg'
import logoAvAudible from '../images/logoAvAudible.svg'

const Distributors = ({ location }) => {

    let isUK = false
    isUK = location.pathname.split('/')[1] === "uk"

    return (
        <>
            <section className="mb-16 p-6 font-sans-serif text-theme-primary-text">
                <div className="container p-4 mx-auto text-center">
                    <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold">Available Now From</h2>
                </div>
                <div className="container flex flex-wrap justify-center mx-auto text-coolGray-600">
                    <div className="flex justify-center p-6 align-middle">
                        {isUK ?
                            <a href="https://www.amazon.co.uk/Think-Positive-Happy-Start-Living/dp/B09JYCTTKZ/ref=as_li_ss_tl?_encoding=UTF8&qid=1634922033&sr=1-3&linkCode=ll1&tag=ajsatori-21&linkId=102c845894a32394f1e98c96e12fd1e4&language=en_GB" target="_blank" rel="noreferrer" aria-label="Purchase on Amazon Books">
                                <img src={logoAvAmazon} alt="" className="h-20" />
                            </a>
                            :
                            <a href="https://www.amazon.com/Think-Positive-Happy-Start-Living/dp/B09JYCTTKZ?_encoding=UTF8&qid=1635250319&sr=8-3&linkCode=ll1&tag=ajsatori-20&linkId=b3665a2c94404cb04e4f440dab979e2e&language=en_US&ref_=as_li_ss_tl" target="_blank" rel="noreferrer" aria-label="Purchase on Amazon Books">
                                <img src={logoAvAmazon} alt="" className="h-20" />
                            </a>
                        }
                    </div>
                    <div className="flex justify-center p-6 align-middle">
                        {isUK ?
                            <a href="https://www.amazon.co.uk/Think-Positive-Happy-Start-Living-ebook/dp/B09JZJZ2T3/ref=as_li_ss_tl?_encoding=UTF8&qid=&sr=&linkCode=ll1&tag=ajsatori-21&linkId=3a0ca141c263d6ef716d7bb40656a66b&language=en_GB" target="_blank" rel="noreferrer" aria-label="Purchase on Amazon Kindle">
                                <img src={logoAvAmazonKindle} alt="" className="h-20" />
                            </a>
                            :
                            <a href="https://www.amazon.com/Think-Positive-Happy-Start-Living-ebook/dp/B09JZJZ2T3?_encoding=UTF8&qid=1635250319&sr=8-3&linkCode=ll1&tag=ajsatori-20&linkId=e3ce7ba2adf334bb9bc4318c00e3e4cd&language=en_US&ref_=as_li_ss_tl" target="_blank" rel="noreferrer" aria-label="Purchase on Amazon Kindle">
                                <img src={logoAvAmazonKindle} alt="" className="h-20" />
                            </a>
                        }
                    </div>
                    <div className="flex justify-center p-6 align-middle relative">
                        {isUK ?
                            <a href="https://www.amazon.co.uk/hz/audible/mlp/mfpdp/B09MZLKTVM?actionCode=AMN30DFT1Bk06604291990WX&tag=ajsatori-21" target="_blank" rel="noreferrer" aria-label="Start your 30-day Audible trial and get this audiobook for free! on Audible">
                                <img src={logoAvAudible} alt="" className="h-20" />
                            </a>
                            :
                            <a href="https://www.amazon.com/gp/search/ref=as_li_qf_sp_sr_tl?ie=UTF8&tag=ajsatori-20&keywords=B09MZMW6TW&index=aps&camp=1789&creative=9325&linkCode=ur2&linkId=fb6824afa1ae6835319262c27b061bf3" target="_blank" rel="noreferrer" aria-label="Purchase on Audiobook on Amazon">
                                <img src={logoAvAudible} alt="" className="h-20" />
                            </a>
                        }
                        {/* <img src={logoAvAudible} alt="" className="h-20" />
                        <div className="absolute left-0"><span className=" text-xs rounded bg-theme-accent1 bg-opacity-80 px-2 py-1 text-theme-primary-text border-2 border-theme-primary-text">Coming Soon</span></div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Distributors