import React from "react";

const Quotes = () => {

    return (

        <>
            <section className=" my-16 bg-theme-accent1">
                <div className="container mx-auto p-6">
                    {/* <div className="flex justify-center py-6">
                        <h2 className="font-sans-serif text-4xl text-theme-secondary-text">Quotes & Affirmations</h2>
                    </div> */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 font-sans-serif text-base md:text-lg text-theme-primary-text">
                        <div className="blockquote-wrapper">
                            <div className="blockquote">
                                <h2>
                                <span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">Today is a gift, that is why it is called the present.</span>
                                </h2>
                                <h3>Eleanor Roosevelt<br /></h3>
                            </div>
                        </div>
                        <div className="blockquote-wrapper">
                            <div className="blockquote">
                                <h2>
                                <span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">People will forget what you said, people will forget what you did, but people will never forget how you made them feel.</span>
                                </h2>
                                <h3>Maya Angelou<br /></h3>
                            </div>
                        </div>
                        <div className="blockquote-wrapper">
                            <div className="blockquote">
                                <h2>
                                <span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">Love Others <br />No one is born hating another person because of the colour of his skin, or his background, or his religion. People must learn to hate, and if they can learn to hate, they can be taught to love, for love comes more naturally to the human heart than its opposite.</span>
                                </h2>
                                <h3>Nelson Mandela<br /></h3>
                            </div>
                        </div>
                        <div className="blockquote-wrapper">
                            <div className="blockquote">
                                <h2>
                                <span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">Spread love everywhere you go.  Let no one ever come to you without leaving happier.</span>
                                </h2>
                                <h3>Mother Teresa<br /></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Quotes