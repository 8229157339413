import React from "react";

const CtaSmall = ({ location }) => {
    let isUK = false
    isUK = location.pathname.split('/')[1] === "uk"
    return (

        <>
            <section className="py-6 text-theme-primary font-sans-serif">
                <div className="container mx-auto flex flex-col justify-around p-4 text-center md:p-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:text-left">
                        <h2 className="py-2 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-medium leading-tight title-font">Do you want to be happier?</h2>
                        <h2 className="py-2 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-medium leading-tight title-font">Do you want to change your behaviour but are not sure how?</h2>
                        <p className="pt-2 mb-1 text-base sm:text-lg md:text-xl font-medium tracking-widest">Have you read self-help books that describe human behaviours in great detail, but provide little in the way of techniques to manage and change the way you think?</p>
                        <p className="pt-2 mb-1 text-base sm:text-lg md:text-xl font-medium tracking-widest">Then this book is for you!</p>
                    </div>
                    <div className="flex flex-col items-center justify-center flex-shrink-0 mt-6 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 lg:ml-4 lg:mt-0 lg:justify-end">
                        {isUK ?
                            <a href="https://www.amazon.co.uk/Think-Positive-Happy-Start-Living/dp/B09JYCTTKZ/ref=as_li_ss_tl?_encoding=UTF8&qid=1634922033&sr=1-3&linkCode=ll1&tag=ajsatori-21&linkId=102c845894a32394f1e98c96e12fd1e4&language=en_GB"
                                target="_blank" rel="noreferrer"
                                className="relative px-8 py-4 ml-4 overflow-hidden uppercase rounded bg-theme-accent2 border-2 border-theme-primary-text text-theme-primary-text focus:outline-none hover:scale-105 transform ease-in-out duration-300">Order Your Copy Now
                                <span className="absolute top-0 right-0 px-5 py-0 text-xs tracking-wider font-thin text-center uppercase origin-bottom-left transform rotate-45 -translate-y-full translate-x-1/3 bg-theme-accent1 border-2 border-theme-primary-text">New</span>
                            </a>
                            :
                            <a href="https://www.amazon.com/Think-Positive-Happy-Start-Living/dp/B09JYCTTKZ?_encoding=UTF8&qid=1635250319&sr=8-3&linkCode=ll1&tag=ajsatori-20&linkId=b3665a2c94404cb04e4f440dab979e2e&language=en_US&ref_=as_li_ss_tl"
                                target="_blank" rel="noreferrer"
                                className="relative px-8 py-4 ml-4 overflow-hidden uppercase rounded bg-theme-accent2 border-2 border-theme-primary-text text-theme-primary-text focus:outline-none hover:scale-105 transform ease-in-out duration-300">Order Your Copy Now
                                <span className="absolute top-0 right-0 px-5 py-0 text-xs tracking-wider font-thin text-center uppercase origin-bottom-left transform rotate-45 -translate-y-full translate-x-1/3 bg-theme-accent1 border-2 border-theme-primary-text">New</span>
                            </a>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default CtaSmall