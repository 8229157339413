import React from "react";

const BookAdds = ({location}) => {
    let isUK = false
    isUK = location.pathname.split('/')[1] ==="uk"
    return (

        <>
            <section className="container mx-auto my-16 p-6">
                <div className="flex justify-center py-6">
                    <h2 className="font-sans-serif text-xl sm:text-2xl md:text-3xl lg:text-4xl text-theme-primary">Recomended Reading</h2>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
                    {isUK?
                        <>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.co.uk/One-unbounded-ocean-consciousness-questions-ebook/dp/B08Z7C9WHB/ref=as_li_ss_il?dchild=1&keywords=One+Unbounded+Ocean+of+Consciousness&qid=1634919130&qsid=259-4895660-7948241&s=digital-text&sr=1-1&sres=B08Z7C9WHB,B089LM5JGJ,B08YXYRSPD,B08YRNW7K2&linkCode=li3&tag=ajsatori-21&linkId=76e91cf4160e9b79cb8930608693d100&language=en_GB" target="_blank" rel="noreferrer">
                                <img className="shadow-md" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08Z7C9WHB&Format=_SL250_&ID=AsinImage&MarketPlace=GB&ServiceVersion=20070822&WS=1&tag=ajsatori-21&language=en_GB" alt="One Unbounded Ocean of Consciousness" />
                            </a>
                        </div>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.co.uk/Chimp-Paradox-Acclaimed-Management-Confidence-ebook/dp/B006K26BEQ/ref=as_li_ss_il?_encoding=UTF8&qid=1634919667&sr=1-1&linkCode=li3&tag=ajsatori-21&linkId=bf5a90df9a7fb91cbec7b8d2c0dc8d26&language=en_GB" target="_blank" rel="noreferrer">
                                <img className="shadow-md" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B006K26BEQ&Format=_SL250_&ID=AsinImage&MarketPlace=GB&ServiceVersion=20070822&WS=1&tag=ajsatori-21&language=en_GB" alt="Chimp Paradox Acclaimed Management Confidence" />
                            </a>
                        </div>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.co.uk/Gift-Sea-Anne-Morrow-Lindbergh/dp/0701188626/ref=as_li_ss_il?crid=34IDE5X5IRVFY&dchild=1&keywords=gift+from+the+sea&qid=1634919981&qsid=259-4895660-7948241&s=books&sr=1-1&sres=0701188626,0880885432,B0028WX2LW,0440419700,1689110228,0140373446,B08HT86X2Q,1785654314,1398503703,1787399583,1916316514,1426206437,0241538165,0008478295,1452174474,1786892731&linkCode=li3&tag=ajsatori-21&linkId=b531bb04201d31a388efb0e1349a3d05&language=en_GB" target="_blank" rel="noreferrer">
                                <img className="shadow-md" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0701188626&Format=_SL250_&ID=AsinImage&MarketPlace=GB&ServiceVersion=20070822&WS=1&tag=ajsatori-21&language=en_GB" alt="Gift from the sea" />
                            </a>
                        </div>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.co.uk/gp/product/B002361MLA/ref=as_li_ss_il?ie=UTF8&linkCode=li3&tag=ajsatori-21&linkId=eef3e30a7ee51e3c737f0ea7afcce0b5&language=en_GB" target="_blank" rel="noreferrer">
                                <img className="shadow-md" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B002361MLA&Format=_SL250_&ID=AsinImage&MarketPlace=GB&ServiceVersion=20070822&WS=1&tag=ajsatori-21&language=en_GB" alt="The Power of Now" />
                            </a>
                        </div>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.co.uk/gp/product/B002RI97IY/ref=as_li_ss_il?ie=UTF8&linkCode=li3&tag=ajsatori-21&linkId=033ede6e30701c5558419158036704e4&language=en_GB" target="_blank" rel="noreferrer">
                                <img className="shadow-md" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B002RI97IY&Format=_SL250_&ID=AsinImage&MarketPlace=GB&ServiceVersion=20070822&WS=1&tag=ajsatori-21&language=en_GB" alt="A New Earth" />
                            </a>
                        </div>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.co.uk/Love-Languages-Secret-that-Lasts-ebook/dp/B00OICLVBI/ref=as_li_ss_il?dchild=1&keywords=The+Five+Love+languages&qid=1634920361&qsid=259-4895660-7948241&s=digital-text&sr=1-1&sres=B00OICLVBI,B01BXPWGX4,B01JBQGD0E,B075SSKD9G,B01BXPWGZ2,B00OICLV0Y,B07F7NYY9P,B09K49Z2D4,B084H8LJ8S,B086JHP86X,B07L4R6CWG,B07T4TCHM5,B084VPR3N2,B09JSX85CW,B004JHY7TA,B00G1TOJ7Y&srpt=ABIS_BOOK&linkCode=li3&tag=ajsatori-21&linkId=13e87fc9afa7067993a690a289a716d1&language=en_GB" target="_blank" rel="noreferrer">
                                <img className="shadow-md" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00OICLVBI&Format=_SL250_&ID=AsinImage&MarketPlace=GB&ServiceVersion=20070822&WS=1&tag=ajsatori-21&language=en_GB" alt="The Five Love Languages" />
                            </a>
                        </div>
                        </>
                    :
                        <>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.com/One-unbounded-ocean-consciousness-questions-ebook/dp/B08Z7C9WHB?dchild=1&keywords=One+Unbounded+Ocean+of+Consciousness&qid=1635256057&qsid=130-4137020-4717005&sr=8-1&sres=B08Z7C9WHB%2CB098FGTMBL%2CB089LM5JGJ%2CB08YRNW7K2%2C1465499334%2CB08LZV1551%2CB08K58GXP4%2C8175230177&srpt=ABIS_EBOOKS&linkCode=li3&tag=ajsatori-20&linkId=edc8d66a7628c28c0e0a48ff6dff903a&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer">
                                <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08Z7C9WHB&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=ajsatori-20&language=en_US" alt="One Unbounded Ocean of Consciousness" />
                            </a>
                        </div>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.com/The-Chimp-Paradox/dp/1785042440?_encoding=UTF8&qid=1635256749&sr=8-1&linkCode=li3&tag=ajsatori-20&linkId=85e7ada674f9aa574eb84f5345821afb&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer">
                                <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=1785042440&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=ajsatori-20&language=en_US" alt="Chimp Paradox Acclaimed Management Confidence" />
                            </a>
                        </div>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.com/Gift-50th-Anniversary-Anne-Morrow-Lindbergh/dp/0679732411?dchild=1&keywords=Gift+from+the+sea&qid=1635257187&qsid=130-4137020-4717005&sr=8-1&sres=0679732411%2CB01FMVORXM%2C0880885432%2CB000ME7Q9W%2C0440419700%2CB000H988O4%2C1880913240%2C0062976583%2CB003YJEXQ6%2C1791007554%2C1481495313%2C1735414123%2C1590035003%2C155152709X%2C0375714928%2CB00KLAXFQ0&linkCode=li3&tag=ajsatori-20&linkId=31c7fb28f73194573de2b1348b7b5897&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer">
                                <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0679732411&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=ajsatori-20&language=en_US" alt="Gift from the sea" />
                            </a>
                        </div>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.com/Power-Now-Guide-Spiritual-Enlightenment/dp/1577314808?dchild=1&keywords=The+Power+of+Now&qid=1635257425&qsid=130-4137020-4717005&sr=8-1&sres=1577314808%2CB0095GVWMA%2C160868637X%2C1529383943%2C1577312198%2C1577311957%2C0140280197%2C8188479446%2C0008386420%2CB08XLLF3PG%2C0452289963%2C1401944590%2CB08R7KF9RT%2C1577314468%2C039335668X%2CB0007OB40E&srpt=ABIS_BOOK&linkCode=li3&tag=ajsatori-20&linkId=5008a71f48adb2635a83fdd6778dc3fc&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer">
                                <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=1577314808&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=ajsatori-20&language=en_US" alt="The Power of Now" />
                            </a>
                        </div>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.com/New-Earth-Awakening-Purpose-Selection/dp/0452289963?_encoding=UTF8&qid=1635257562&sr=8-1&linkCode=li3&tag=ajsatori-20&linkId=c209eed2cf1b4697e9c9d9d83f20e96b&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer">
                                <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0452289963&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=ajsatori-20&language=en_US" alt="A New Earth" />
                            </a>
                        </div>
                        <div className="flex justify-center p-2">
                            <a href="https://www.amazon.com/Love-Languages-Secret-that-Lasts/dp/080241270X?dchild=1&keywords=The+Five+Love+Languages&qid=1635257676&qsid=130-4137020-4717005&sr=8-1&sres=080241270X%2C8186775099%2C0802412858%2C0802412726%2C0802412718%2CB07P8BC6T2%2C080241284X%2C1415857318%2C0802414826%2C0802414354%2C1881273156%2CB09DMXTMCW%2C1876825545%2C1881273377%2C8904141184%2C1500960276&srpt=ABIS_BOOK&linkCode=li3&tag=ajsatori-20&linkId=f9ff1be282edf333a6a6ca8595d8aee7&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer">
                                <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=080241270X&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=ajsatori-20&language=en_US" alt="The Five Love Languages" />
                            </a>
                        </div>
                        </>
                    }
                </div>
            </section>
        </>
    )
}

export default BookAdds