import React from "react";

const Downloads = () => {

    return (

        <>
        <section className="my-16 py-12 text-theme-primary-text bg-theme-accent1 font-sans-serif">
            <div className="container mx-auto flex flex-col items-center justify-center p-4  md:p-10  lg:flex-row lg:justify-around">
                <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold leading-tight text-center lg:text-left pb-6 lg:pb-0">Get a FREE copy of the daily activity check list!</h1>
                <a href="/daily-checklist-think-positive-be-happy-start-living.pdf" download className="px-8 py-3 text-lg font-semibold rounded text-theme-primary-text bg-theme-accent2 border-2 border-theme-primary-text focus:outline-none hover:scale-105 transform ease-in-out duration-300">Download</a>
            </div>
        </section>
        </>
    )
}

export default Downloads