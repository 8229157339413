import React from "react";
// import { StaticImage } from "gatsby-plugin-image"

// import icon from '../images/sunCharacter.svg'
import logo from '../images/logo.svg'
import bulb1 from '../images/bulb-01.svg'
import bulb2 from '../images/bulb-02.svg'
import bulb3 from '../images/bulb-03.svg'

const Profile = () => {

    return (

        <>
            <section className="mb-16 p-6 sm:p-12 font-sans-serif">
                <div className="container mx-auto flex flex-col p-4 md:p-10 md:px-24 xl:px-48">
                    <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-6 md:flex-row">
                        {/* <img src={icon} alt="" className="self-center flex-shrink-0 w-32 md:justify-self-start" /> */}
                        {/* <StaticImage className="self-center flex-shrink-0 w-64 md:justify-self-start" src="../images/sunCharacter.jpg" alt="AJ Satori - Author" placeholder="blurred" layout="constrained" /> */}
                        <div className="flex flex-col text-base sm:text-lg md:text-xl text-theme-primary-text">
                            <img src={logo} alt="AJ Satori - Author" className="w-24 sm:w-28 md:w-32 pb-6" />
                            <p className="text-justify py-2">Highly observant of human behaviours from anxiety to stubbornness.  Drawing from personal experiences and knowledge to give a frank analysis of the way we can act occasionally and provides us with the information required on how to make the necessary changes in our daily lives. </p>
                            <p className="text-justify py-2">A J Satori is passionate about others developing a positive mindset and to become AWARE.  This book makes you question and analyse your own behaviours and gives you the tools to change them. </p>
                        </div>
                    </div>
                    <div className="flex justify-center pt-4 space-x-4 align-center">
                        <a href="https://www.instagram.com/aj_satori/" target="_blank" rel="noreferrer" aria-label="Instagram" className="p-2 rounded-md text-theme-primary-text hover:text-theme-accent1 focus:outline-none hover:scale-105 transform ease-in-out duration-300">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.1" viewBox="0 0 16 16" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 0h-13c-0.825 0-1.5 0.675-1.5 1.5v13c0 0.825 0.675 1.5 1.5 1.5h13c0.825 0 1.5-0.675 1.5-1.5v-13c0-0.825-0.675-1.5-1.5-1.5zM11 2.5c0-0.275 0.225-0.5 0.5-0.5h2c0.275 0 0.5 0.225 0.5 0.5v2c0 0.275-0.225 0.5-0.5 0.5h-2c-0.275 0-0.5-0.225-0.5-0.5v-2zM8 5c1.656 0 3 1.344 3 3s-1.344 3-3 3c-1.656 0-3-1.344-3-3s1.344-3 3-3zM14 13.5v0c0 0.275-0.225 0.5-0.5 0.5h-11c-0.275 0-0.5-0.225-0.5-0.5v0-6.5h1.1c-0.066 0.322-0.1 0.656-0.1 1 0 2.762 2.237 5 5 5s5-2.238 5-5c0-0.344-0.034-0.678-0.1-1h1.1v6.5z"></path></svg>
                        </a>
                        {/* <a href="#" aria-label="Twitter" className="p-2 rounded-md text-theme-primary-text hover:text-theme-accent1 focus:outline-none hover:scale-105 transform ease-in-out duration-300">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.1" viewBox="0 0 16 16" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M16 3.538c-0.588 0.263-1.222 0.438-1.884 0.516 0.678-0.406 1.197-1.050 1.444-1.816-0.634 0.375-1.338 0.65-2.084 0.797-0.6-0.638-1.453-1.034-2.397-1.034-1.813 0-3.281 1.469-3.281 3.281 0 0.256 0.028 0.506 0.084 0.747-2.728-0.138-5.147-1.444-6.766-3.431-0.281 0.484-0.444 1.050-0.444 1.65 0 1.138 0.578 2.144 1.459 2.731-0.538-0.016-1.044-0.166-1.488-0.409 0 0.013 0 0.028 0 0.041 0 1.591 1.131 2.919 2.634 3.219-0.275 0.075-0.566 0.116-0.866 0.116-0.212 0-0.416-0.022-0.619-0.059 0.419 1.303 1.631 2.253 3.066 2.281-1.125 0.881-2.538 1.406-4.078 1.406-0.266 0-0.525-0.016-0.784-0.047 1.456 0.934 3.181 1.475 5.034 1.475 6.037 0 9.341-5.003 9.341-9.341 0-0.144-0.003-0.284-0.009-0.425 0.641-0.459 1.197-1.038 1.637-1.697z"></path></svg>
                        </a>
                        <a href="#" aria-label="Facebook" className="p-2 rounded-md text-theme-primary-text hover:text-theme-accent1 focus:outline-none hover:scale-105 transform ease-in-out duration-300">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.1" viewBox="0 0 16 16" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 0h-13c-0.825 0-1.5 0.675-1.5 1.5v13c0 0.825 0.675 1.5 1.5 1.5h6.5v-7h-2v-2h2v-1c0-1.653 1.347-3 3-3h2v2h-2c-0.55 0-1 0.45-1 1v1h3l-0.5 2h-2.5v7h4.5c0.825 0 1.5-0.675 1.5-1.5v-13c0-0.825-0.675-1.5-1.5-1.5z"></path></svg>
                        </a> */}
                        <a href="#contact" aria-label="Contact" className="p-2 rounded-md text-theme-primary-text hover:text-theme-accent1 focus:outline-none hover:scale-105 transform ease-in-out duration-300">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0 0 68.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"></path></svg>
                        </a>
                    </div>
                    <div className="flex flex-row justify-around md:flex-row pt-6">
                        <div><img src={bulb1} alt="AJ Satori - Author" className="w-20 sm:w-28 md:w-32" /></div>
                        <div><img src={bulb2} alt="AJ Satori - Author" className="w-20 sm:w-28 md:w-32" /></div>
                        <div><img src={bulb3} alt="AJ Satori - Author" className="w-20 sm:w-28 md:w-32" /></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Profile