import React from "react";

const CtaLarge = ({ location }) => {
    let isUK = false
    isUK = location.pathname.split('/')[1] === "uk"
    return (

        <>
            <section className="py-6 bg-theme-accent1 text-theme-primary-text font-sans-serif">
                <div className="container mx-auto flex flex-col items-center justify-center p-4 md:p-10 md:px-24 xl:px-48">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-none text-center pb-6">Push Forward Positivity (PFP)</h1>
                    <p className="pt-2 pb-8 text-base sm:text-lg md:text-xl font-medium text-center">This book provides you with a Toolkit to make changes in your life, to be positive and to start truly living.  We can often forget what it is we need to do to make ourselves feel better. This is a first-hand guide to go to when you want to feel your best. Condensed into an easy-to-read format with the essentials you need to know and more importantly do to lead a happier, healthier, positive life.</p>
                    {isUK ?
                        <a href="https://www.amazon.co.uk/Think-Positive-Happy-Start-Living/dp/B09JYCTTKZ/ref=as_li_ss_tl?_encoding=UTF8&qid=1634922033&sr=1-3&linkCode=ll1&tag=ajsatori-21&linkId=102c845894a32394f1e98c96e12fd1e4&language=en_GB"
                            target="_blank" rel="noreferrer"
                            className="relative px-8 py-4 ml-4 overflow-hidden uppercase rounded bg-theme-accent2 border-2 border-theme-primary-text text-theme-primary-text focus:outline-none hover:scale-105 transform ease-in-out duration-300">Order Your Copy Now
                            <span className="absolute top-0 right-0 px-5 py-0 text-xs tracking-wider font-thin text-center uppercase origin-bottom-left transform rotate-45 -translate-y-full translate-x-1/3 bg-theme-accent1 border-2 border-theme-primary-text">New</span>
                        </a>
                        :
                        <a href="https://www.amazon.com/Think-Positive-Happy-Start-Living/dp/B09JYCTTKZ?_encoding=UTF8&qid=1635250319&sr=8-3&linkCode=ll1&tag=ajsatori-20&linkId=b3665a2c94404cb04e4f440dab979e2e&language=en_US&ref_=as_li_ss_tl"
                            target="_blank" rel="noreferrer"
                            className="relative px-8 py-4 ml-4 overflow-hidden uppercase rounded bg-theme-accent2 border-2 border-theme-primary-text text-theme-primary-text focus:outline-none hover:scale-105 transform ease-in-out duration-300">Order Your Copy Now
                            <span className="absolute top-0 right-0 px-5 py-0 text-xs tracking-wider font-thin text-center uppercase origin-bottom-left transform rotate-45 -translate-y-full translate-x-1/3 bg-theme-accent1 border-2 border-theme-primary-text">New</span>
                        </a>
                    }
                </div>
            </section>
        </>
    )
}

export default CtaLarge