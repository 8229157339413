import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const Gallery = () => {

    return (

        <>
            <section className="pt-16 bg-coolGray-100 text-coolGray-900">
                <div className="container grid grid-cols-2 gap-4 p-4 mx-auto md:grid-cols-4">
                    <StaticImage className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-3 md:row-start-1" src="../images/gallery/think-positive-paper-back-on-green-garden-bush.jpg" alt="Think Positive, Be Happy, Start Living" placeholder="blurred" layout="constrained" />
                    <StaticImage className="w-full h-full rounded shadow-sm min-h-48" src="../images/gallery/think-positive-paper-back-girl-holding-a-book.jpg" alt="Think Positive, Be Happy, Start Living" placeholder="blurred" layout="constrained" />
                    <StaticImage className="w-full h-full rounded shadow-sm min-h-48" src="../images/gallery/think-positive-paper-back-surrounded-by-girly-stuff.jpg" alt="Think Positive, Be Happy, Start Living" placeholder="blurred" layout="constrained" />
                    <StaticImage className="w-full h-full rounded shadow-sm min-h-48" src="../images/gallery/think-positive-paper-back-woman-in-a-cozy-setting.jpg" alt="Think Positive, Be Happy, Start Living" placeholder="blurred" layout="constrained" />
                    <StaticImage className="w-full h-full rounded shadow-sm min-h-48" src="../images/gallery/think-positive-paper-back-woman-reading-2.jpg" alt="Think Positive, Be Happy, Start Living" placeholder="blurred" layout="constrained" />
                </div>
            </section>
        </>
    )
}

export default Gallery